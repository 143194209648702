html, body, #root {
  width: 100%;
  height: 100%;
  margin:0;
  overflow: hidden;
}

.app-root{
  width: 100%;
  height: 100%;
  background-color: #282c34;
  background-image: url('images/fishing_bg.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.app-logo {
  position: absolute;
  bottom: 45px;
  height: 100px;
  width: 80%;
  margin: auto;
  background-image: url('images/gone_fishing.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-left: 10%;
  box-sizing: border-box;
}

@media only screen and (min-width: 1200px) {
  .app-logo {
    margin: auto;
    width: 100%;
    background-position: right;
    background-position-x: 95%;
  }
}

@media only screen and (min-width: 1700px) {
  .app-logo {
    height: 125px;
  }
}

@media only screen and (min-width: 1900px) {
  .app-logo {
    height: 150px;
  }
}

@media only screen and (min-width: 2500px) {
  .app-logo {
    height: 200px;
  }
}